import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import MovezeeIcon from "assets/svgs/icons/mz-icon.svg"

import * as Styled from "./styles"

type FooterSectionTitle = string
type SectionType = "about"
interface FooterLink {
  node: {
    id: string
    frontmatter: {
      title: string
      sectionType: string
      slug: string
    }
  }
}

export const footerQuery = graphql`
  query FooterData {
    allMarkdownRemark(
      filter: { frontmatter: { category: { eq: "footer link" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            slug
            sectionType
          }
        }
      }
    }
    markdownRemark(frontmatter: { category: { eq: "footer" } }) {
      frontmatter {
        sections
      }
    }
  }
`

const Footer: React.FC = () => {
  const { markdownRemark, allMarkdownRemark } = useStaticQuery(footerQuery)

  const sections: FooterSectionTitle[] = markdownRemark.frontmatter.sections
  const links: FooterLink[] = allMarkdownRemark.edges

  const filteredLinks = (links: FooterLink[], sectionType: SectionType) => {
    return links.filter(
      link => link.node.frontmatter.sectionType === sectionType
    )
  }

  const renderLinks = (links: FooterLink[], sectionType: SectionType) => {
    return filteredLinks(links, sectionType).map(link => {
      const { id, frontmatter } = link.node
      const { title, slug } = frontmatter

      return (
        <Styled.InternalLink key={id} to={slug}>
          {title}
        </Styled.InternalLink>
      )
    })
  }

  return (
    <Styled.Footer>
      <Styled.Wrapper>
        <MovezeeIcon />
        <Styled.ContentWrapper>
          <Styled.ListWrapper>
            <Styled.Subtitle>{sections[0]}</Styled.Subtitle>
            {renderLinks(links, "about")}
          </Styled.ListWrapper>
        </Styled.ContentWrapper>
        <Styled.Copyright>
          {`© ${new Date().getFullYear()} Movezee, All rights reserved`}
        </Styled.Copyright>
      </Styled.Wrapper>
    </Styled.Footer>
  )
}

export default Footer
