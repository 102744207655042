import React, { useEffect, useMemo, useState } from "react"
import { navigate } from "gatsby"
import { useMediaQuery } from "react-responsive"

import Icon from "components/ui/Icon"
import Button from "components/ui/Button"
import MovezeeIcon from "assets/svgs/icons/mz-icon.svg"

import { LandingPagesProps } from "types/interfaces"
import { LandingPageType } from "types/enums"
import { globalHistory } from "@reach/router"

import * as Styled from "./styles"
import { CalendlyPopup } from "components/CalendlyPopup"
import {
  CoiNavItems,
  homeNavItems,
  MainNavItem,
  mcNavItems,
  vendorsNavItems,
} from "./constants"
import { useCalendly } from "hooks/useCalendly"

const MainNav: React.FC<LandingPagesProps> = ({ landingType }) => {
  const [open, setOpen] = useState<boolean>(false)
  const { isCalendlyPopupOpen, setCalendlyPopupOpen } = useCalendly()

  const isMenuMobile = useMediaQuery({
    query: "(max-width: 1023px)",
  })

  const scrollToContactForm = () => {
    const contactForm = document.getElementById("contact-form")
    contactForm?.scrollIntoView({ behavior: "smooth" })
    if (open) setOpen(false)
  }

  const redirectToSignIn = () =>
    navigate(`${process.env.GATSBY_APP_BASE_URL}/auth/signin`)

  const redirectToSignUp = () =>
    navigate(`${process.env.GATSBY_APP_BASE_URL}/auth/signup`)

  const secondaryActionButtonLabel =
    landingType === LandingPageType.MANAGEMENT_COMPANIES
      ? "Request a demo"
      : "Get Started"

  const secondaryActionButtonAction =
    landingType === LandingPageType.MANAGEMENT_COMPANIES
      ? () => setCalendlyPopupOpen(true)
      : () => redirectToSignUp()

  const navigationItems = useMemo<MainNavItem[]>(() => {
    switch (landingType) {
      case LandingPageType.HOME:
        return homeNavItems
      case LandingPageType.MANAGEMENT_COMPANIES:
        return mcNavItems
      case LandingPageType.VENDORS:
        return vendorsNavItems
      default:
        return CoiNavItems
    }
  }, [landingType])

  const handleToggleMenu = () => {
    document.querySelector("body")?.classList.toggle("fixed-body")
    setOpen(prev => !prev)
  }

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === "PUSH") {
        document.querySelector("body")?.classList.remove("fixed-body")
      }
    })
  }, [])

  return (
    <>
      <CalendlyPopup
        isOpen={isCalendlyPopupOpen}
        onClose={() => setCalendlyPopupOpen(false)}
      />
      <Styled.MainNav open={open}>
        {isMenuMobile ? (
          <Icon visible={open}>
            <MovezeeIcon />
          </Icon>
        ) : null}
        <Styled.LinksWrapper>
          {navigationItems.map((item, index) => (
            <Styled.MainNavItem
              key={`nav-item-${index}`}
              to={item.slug}
              activeClassName="active"
              whileTap={{ scale: 0.9 }}
            >
              {item.title}
            </Styled.MainNavItem>
          ))}
          {landingType && (
            <Styled.MainContactItem onClick={() => scrollToContactForm()}>
              {"Contact"}
            </Styled.MainContactItem>
          )}
        </Styled.LinksWrapper>

        <Styled.ActionButtonsGroup>
          <Button
            landingType={landingType}
            mobileMargin="1.5rem 0 0"
            primary
            shadow
            onClick={() => redirectToSignIn()}
          >
            {"Log in"}
          </Button>
          <Button
            landingType={landingType}
            mobileMargin="1rem 0 0"
            secondary
            shadow
            onClick={secondaryActionButtonAction}
          >
            {secondaryActionButtonLabel}
          </Button>
        </Styled.ActionButtonsGroup>
      </Styled.MainNav>
      <Styled.ToggleMainNav open={open} onClick={handleToggleMenu}>
        <span />
        <span />
        <span />
      </Styled.ToggleMainNav>
    </>
  )
}

export default MainNav
