import styled from "styled-components"

export interface StyledProps {
  visible?: boolean
}

export const Icon = styled.span<StyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.5rem;
  height: 3.5rem;

  ${({ visible }) =>
    visible && !visible ? "visibility: hidden;" : "visibility: visible;"}

  ${({ theme }) => theme.breakpoints.desktop} {
    width: 4.5rem;
    height: 4.5rem;
  }
`
