import styled from "styled-components"
import { motion } from "framer-motion"
import { LandingPageType } from "types/enums"
export interface StyledProps {
  primary?: boolean
  secondary?: boolean
  shadow?: boolean
  activeShadow?: boolean
  landingType?: LandingPageType
  mobileMargin?: string
  disableMargin?: boolean
  greenButton?: boolean
  desktopWidth?: {
    smallDesktop?: string
    desktop?: string
  }
}

export const Button = motion(styled.button<StyledProps>`
  outline: none !important;
  padding: 0.8rem 2rem;
  border: none;
  border-radius: 8px;
  white-space: nowrap;
  margin: ${({ mobileMargin }) => (mobileMargin ? mobileMargin : "1.5rem 0")};
  font-size: 16px;
  font-weight: 600;
  line-height: 1.375;
  width: 100%;
  ${({ activeShadow }) =>
    activeShadow && "box-shadow: 0px 15px 70px -10px #091F39;"}
  color: ${({ primary, theme, landingType }) => {
    switch (landingType) {
      case LandingPageType.HOME:
      case LandingPageType.COI:
        if (primary) return theme.colors.buttons.primary.text
        return theme.colors.basic2
      case LandingPageType.MANAGEMENT_COMPANIES:
        if (primary) return theme.colors.buttons.primary.text
        return theme.colors.buttons.secondary.text
      case LandingPageType.VENDORS:
        if (primary) return theme.colors.buttons.primary.text
        return theme.colors.text4
      default:
        if (primary) return theme.colors.buttons.primary.text
        return theme.colors.basic2
    }
  }};
  font-family: ${({ theme }) => theme.fonts.main2};
  background-color: ${({ primary, theme, landingType }) => {
    if (!primary) {
      switch (landingType) {
        case LandingPageType.HOME:
        case LandingPageType.COI:
          return theme.colors.background3
        case LandingPageType.MANAGEMENT_COMPANIES:
          return theme.colors.buttons.secondary.bg
        case LandingPageType.VENDORS:
          return theme.colors.background4
        default:
          return theme.colors.background3
      }
    }
  }};
  background-image: ${({ primary, theme, landingType, greenButton }) => {
    if (primary && greenButton) return theme.gradient.gradientGreen
    else if (primary) {
      switch (landingType) {
        case LandingPageType.HOME:
        case LandingPageType.COI:
          return theme.gradient.gradientBlue
        case LandingPageType.MANAGEMENT_COMPANIES:
          return theme.gradient.gradientOrange
        case LandingPageType.VENDORS:
          return theme.gradient.gradientViolet
        default:
          return theme.gradient.gradientBlue
      }
    }
  }}
  }};
  transition: all 0.2s ease-in-out;

  &:hover {
    cursor: pointer;
    ${({ shadow, landingType, greenButton }) =>
      shadow &&
      `box-shadow: 0px 15px 30px -5px rgba(${
        landingType === (LandingPageType.HOME || LandingPageType.COI)
          ? "0, 174, 239, 0.4"
          : landingType === LandingPageType.VENDORS
          ? "116, 93, 255, 0.4"
          : landingType === LandingPageType.MANAGEMENT_COMPANIES
          ? "255, 144, 81, 0.4"
          : greenButton
          ? "84, 238, 217, 0.4"
          : "0, 174, 239, 0.4"
      });`};
  }

  ${({ theme }) => theme.breakpoints.smallDesktop} {
    margin: ${({ disableMargin }) =>
      disableMargin ? 0 : "1.5rem 0.5rem 1.5rem 0"};
    padding: 0.8rem 2.5rem;
    width: ${({ desktopWidth }) => desktopWidth?.smallDesktop ?? "auto"};
  }

  ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 18px;
    margin: ${({ disableMargin }) =>
      disableMargin ? 0 : "1.5rem 1rem 1.5rem 0"};
      width: ${({ desktopWidth }) => desktopWidth?.desktop ?? "auto"};
  }
`)
