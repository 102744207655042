import styled from "styled-components"
import { FlexWrapper } from "components/ui/FlexWrapper/styles"

export const Layout = styled.main`
  width: 100%;
  position: relative;
  background: ${({ theme }) => theme.colors.background1};
  ${({ theme }) =>
    `background: linear-gradient(0deg, ${theme.colors.background2} 0%, ${theme.colors.background1} 100%);`};
`

export const PageWrapper = styled(FlexWrapper)`
  min-height: 100vh;
  max-width: 1920px;
  margin: 0 auto;
`

export const ContentWrapper = styled(FlexWrapper)`
  margin: 64px auto 250px;
  max-width: 500px;

  ${({ theme }) => theme.breakpoints.smallDesktop} {
    margin: 142px auto 300px;
    padding: 0 3rem;
    max-width: 1440px;
  }

  ${({ theme }) => theme.breakpoints.desktop} {
    padding: 0 2rem;
  }
`
