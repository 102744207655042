import React from "react"

import Logo from "./Logo"
import MainNav from "./MainNav"

import { LandingPagesProps } from "types/interfaces"

import * as Styled from "./styles"

const Header: React.FC<LandingPagesProps> = ({ landingType }) => (
  <Styled.Header>
    <Styled.Wrapper>
      <Styled.ContentWrapper>
        <Logo />
        <MainNav landingType={landingType} />
      </Styled.ContentWrapper>
    </Styled.Wrapper>
  </Styled.Header>
)

export default Header
