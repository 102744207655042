import styled from "styled-components"
import { Link } from "gatsby"

export const Logo = styled(Link)`
  width: 114px;

  ${({ theme }) => theme.breakpoints.smallDesktop} {
    width: 200px;
  }

  ${({ theme }) => theme.breakpoints.desktop} {
    width: 255px;
  }
`

export const Image = styled.figure`
  width: 100%;
`
