import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 768px;
  width: 100%;
  margin: 0 auto;
  padding: 1.25rem;
`
