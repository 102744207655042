import React from "react"

import * as Styled from "./styles"

interface Props extends Styled.StyledProps {
  children: React.ReactComponentElement<any>
}

const Icon: React.FC<Props> = ({ children, visible }) => (
  <Styled.Icon visible={visible}>{children}</Styled.Icon>
)

export default Icon
