import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import ImageWrapper from "components/ui/ImageWrapper"

import * as Styled from "./styles"

const Logo: React.FC = () => {
  return (
    <Styled.Logo to="/">
      <ImageWrapper>
        <StaticImage
          src="../../../images/logo-mz-2.svg"
          alt="logo-movezee"
          placeholder="blurred"
        />
      </ImageWrapper>
    </Styled.Logo>
  )
}

export default Logo
