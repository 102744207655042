import styled from "styled-components"
import { Container } from "components/ui/Container/styles"

export const Header = styled.header`
  margin-bottom: -1px;
  width: 100vw;
  background: ${({ theme }) => theme.colors.background1};
`

export const Wrapper = styled(Container)`
  position: fixed;
  z-index: 10;
  background-color: ${({ theme }) => theme.colors.background1};
  width: 100vw;
  left: 0;
  max-width: none;
`

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
`
