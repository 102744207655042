export interface MainNavItem {
  title: string
  slug: string
}

export const homeNavItems: MainNavItem[] = [
  {
    title: "For Management Companies",
    slug: "/for-management-companies/",
  },
  {
    title: "For Vendors",
    slug: "/for-vendors/",
  },
  {
    title: "About COI",
    slug: "/coi/",
  },
]

export const mcNavItems: MainNavItem[] = [
  {
    title: "Home",
    slug: "/",
  },
  {
    title: "For Vendors",
    slug: "/for-vendors/",
  },
  {
    title: "About COI",
    slug: "/coi/",
  },
]

export const vendorsNavItems: MainNavItem[] = [
  {
    title: "Home",
    slug: "/",
  },
  {
    title: "For Management Companies",
    slug: "/for-management-companies/",
  },
  {
    title: "About COI",
    slug: "/coi/",
  },
]

export const CoiNavItems: MainNavItem[] = [
  {
    title: "Home",
    slug: "/",
  },
  {
    title: "For Management Companies",
    slug: "/for-management-companies/",
  },
  {
    title: "For Vendors",
    slug: "/for-vendors/",
  },
  {
    title: "About COI",
    slug: "/coi/",
  },
]
