import styled from "styled-components"

export interface StyledProps {
  alignCenter?: boolean
}

export const FlexWrapper = styled.div<StyledProps>`
  display: flex;
  flex-direction: column;
  align-items: ${({ alignCenter }) => (alignCenter ? "center" : "flex-start")};
`
