import styled, { css } from "styled-components"
import { FlexWrapper } from "components/ui/FlexWrapper/styles"
import { AnchorLink } from "gatsby-plugin-anchor-links"

export const Footer = styled.footer`
  background-color: ${props => props.theme.colors.background2};
  position: absolute;
  display: block;
  min-width: 100%;
  width: 100%;
  right: 0;
  left: 0;
  bottom: 1.5rem;
`

export const Wrapper = styled(FlexWrapper)`
  margin: 0 auto;
  max-width: 1920px;
  position: relative;
  padding: 3rem 2rem;

  ${({ theme }) => theme.breakpoints.tablet} {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 3rem 3rem 6rem 3rem;
  }

  ${({ theme }) => theme.breakpoints.smallDesktop} {
    padding: 3rem 6rem 6rem 9rem;
  }
`

export const ContentWrapper = styled(FlexWrapper)`
  ${({ theme }) => theme.breakpoints.tablet} {
    flex-direction: row;
  }
`

export const ListWrapper = styled(FlexWrapper)`
  flex-wrap: nowrap;
  margin-top: 2rem;

  ${({ theme }) => theme.breakpoints.tablet} {
    min-width: 10rem;
    margin-top: 0;
  }

  ${({ theme }) => theme.breakpoints.desktop} {
    min-width: 15rem;
  }
`

export const Copyright = styled.p`
  position: absolute;
  font-size: 12px;
  color: ${props => props.theme.colors.supporting};
  bottom: 0;
  left: 2rem;

  ${({ theme }) => theme.breakpoints.tablet} {
    bottom: 3rem;
    font-size: 16px;
    left: 3rem;
  }

  ${({ theme }) => theme.breakpoints.smallDesktop} {
    left: 9rem;
  }
`

export const Subtitle = styled.h3`
  color: ${props => props.theme.colors.text1};
  font-size: 16px;
  padding-bottom: 0.75rem;
  font-weight: 700;

  ${({ theme }) => theme.breakpoints.tablet} {
    font-size: 20px;
    line-height: 30px;
  }
`

const sharedLinkStyles = css`
  color: ${props => props.theme.colors.supporting};
  font-size: 12px;
  line-height: 22px;
  margin: 0.25rem 0;
  cursor: pointer;

  ${({ theme }) => theme.breakpoints.tablet} {
    font-size: 16px;
    line-height: 22px;
    margin: 0.5rem 0;

    &:hover {
      color: ${({ theme }) => theme.colors.text2};
    }
  }
`

export const ExternalLink = styled.a`
  ${sharedLinkStyles}
`

export const InternalLink = styled(AnchorLink)`
  ${sharedLinkStyles}
`
