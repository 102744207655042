import { createGlobalStyle } from "styled-components"

export default createGlobalStyle`
  body {
    font-family: 'DM sans', sans-serif;
    overflow-x: hidden;
  }

  * {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  background-color: transparent;
  background-image: none;
  border: none;
}

.fixed-body {
  position: fixed;
}
`
