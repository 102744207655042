import styled from "styled-components"

export const ImageWrapper = styled.figure`
  margin: 0 auto;
  width: 100%;

  svg {
    width: 100%;
    height: auto;
    pointer-events: none;
  }
`
