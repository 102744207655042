import * as React from "react"
import { motion, AnimatePresence } from "framer-motion"

import Header from "components/Header"
import Footer from "components/Footer"

import { LandingPagesProps } from "types/interfaces"

import * as Styled from "./styles"
import GlobalStyles from "styles/globalStyles"

interface Props extends LandingPagesProps {
  children: React.ReactNode
}

const Layout = ({ landingType, children }: Props) => {
  return (
    <>
      <GlobalStyles />
      <AnimatePresence exitBeforeEnter>
        <motion.div
          initial={{ y: 0, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ delay: 0.2 }}
        >
          <Styled.Layout>
            <Styled.PageWrapper>
              <Header landingType={landingType} />
              <Styled.ContentWrapper>{children}</Styled.ContentWrapper>
              <Footer />
            </Styled.PageWrapper>
          </Styled.Layout>
        </motion.div>
      </AnimatePresence>
    </>
  )
}

export default Layout
