import React from "react"
import { useEffect } from "react"
import { PopupModal } from "react-calendly"

interface CalendlyPopupProps {
  isOpen: boolean
  onClose: () => void
}

export const CalendlyPopup = ({ isOpen, onClose }: CalendlyPopupProps) => {
  const calendlyUrl = process.env.GATSBY_CALENDLY_URL || ""
  const [root, setRoot] = React.useState<HTMLElement | null>(null)

  useEffect(() => {
    setRoot(document.getElementById("___gatsby"))
  })

  if (!root) {
    return null
  }

  return (
    <PopupModal
      url={calendlyUrl}
      onModalClose={onClose}
      open={isOpen}
      rootElement={root}
    />
  )
}
