import React from "react"

import * as Styled from "./styles"
interface Props extends Styled.StyledProps {
  onClick?: () => void
  children: React.ReactNode
}

type ButtonProps = Props & React.ButtonHTMLAttributes<HTMLButtonElement>

const Button: React.FC<ButtonProps> = ({
  primary,
  secondary,
  shadow,
  children,
  landingType,
  onClick,
  mobileMargin,
  disableMargin,
  activeShadow,
  greenButton,
  desktopWidth,
}) => (
  <Styled.Button
    primary={primary}
    secondary={secondary}
    landingType={landingType}
    shadow={shadow}
    activeShadow={activeShadow}
    mobileMargin={mobileMargin}
    disableMargin={disableMargin}
    greenButton={greenButton}
    desktopWidth={desktopWidth}
    onClick={onClick}
  >
    {children}
  </Styled.Button>
)

export default Button
