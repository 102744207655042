import styled from "styled-components"
import { Link } from "gatsby"
import { motion } from "framer-motion"
import { Container } from "components/ui/Container/styles"

interface StyledProps {
  open: boolean
}

export const MainNav = styled.nav<StyledProps>`
  display: none;
  align-items: center;
  flex-direction: column;
  width: 100%;
  order: 9999;
  position: fixed;
  padding: 64px 1rem 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
  margin-top: 0;
  background: white;

  ${({ theme }) => theme.breakpoints.smallDesktop} {
    position: unset;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    flex-wrap: nowrap;
    justify-content: flex-end;
    width: auto;
    order: 0;
    padding: 0;
  }

  ${({ open }) => open && "display: flex;"}
`

export const ToggleMainNav = styled.button<StyledProps>`
  display: flex;
  outline: none !important;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  cursor: pointer;
  width: 1.5rem;
  height: 1.5rem;
  z-index: 10;

  ${({ theme }) => theme.breakpoints.smallDesktop} {
    display: none;
  }

  span {
    display: inline-block;
    width: 1.5rem;
    height: 3px;
    border-radius: 2px;
    background-color: ${({ theme }) => theme.colors.basic};
    transition: 0.2s;
    &:first-child {
      ${({ open }) =>
        open ? "margin-bottom: -3px" : "margin-bottom: 0.25rem"};
      transform: ${({ open }) => (open ? "rotate(45deg)" : "none")};
    }
    &:last-child {
      ${({ open }) => (open ? "margin-top: -2.5px" : "margin-top: 0.25rem")};
      width: ${open => (open ? "1.5rem" : "1rem")};
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "none")};
    }
    &:nth-child(2) {
      ${({ open }) => (open ? "opacity: 0" : "opacity: 1")};
      width: 1.25rem;
      transform: ${({ open }) => (open ? "translate(20px)" : "none")};
    }
  }
`

export const LinksWrapper = styled(Container)`
  margin: 2rem 0;
  flex-direction: column;
  align-items: center;

  ${({ theme }) => theme.breakpoints.smallDesktop} {
    margin: 0;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
  }
`

export const MainNavItem = motion(styled(Link)`
  position: relative;
  width: max-content;
  font-size: 16px;
  line-height: 1.375;
  margin: 1.5rem 0;
  color: ${({ theme }) => theme.colors.text1};
  transition: color 0.2s ease-in-out;

  ${({ theme }) => theme.breakpoints.smallDesktop} {
    margin: 0 0.5rem;
  }

  ${({ theme }) => theme.breakpoints.desktop} {
    margin: 0 1.5rem;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.text2};
  }
`)

export const MainContactItem = styled.span`
  position: relative;
  width: max-content;
  font-size: 16px;
  line-height: 1.375;
  margin: 1.5rem 0;
  color: ${({ theme }) => theme.colors.text1};
  transition: color 0.2s ease-in-out;
  cursor: pointer;

  ${({ theme }) => theme.breakpoints.smallDesktop} {
    margin: 0 0.5rem;
  }

  ${({ theme }) => theme.breakpoints.desktop} {
    margin: 0 1.5rem;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.text2};
  }
`

export const ActionButtonsGroup = styled.div`
  display: flex;
  flex-flow: row-reverse;
  @media (max-width: 1023px) {
    flex-direction: column;
    width: 100%;
  }
`
