import { useState } from "react"

export const useCalendly = () => {
  const [isCalendlyPopupOpen, setCalendlyPopupOpen] = useState<boolean>(false)

  return {
    isCalendlyPopupOpen,
    setCalendlyPopupOpen,
  }
}
